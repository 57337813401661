@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primary: #3c81e9;
  --secondary: #f9f9f9;
  --main_text: #222222;
  --sec_text: #a4a4a4;
  --family-Inter: "Inter", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: var(--family-Inter) !important;
  color: var(--main_text);
}

body {
  font-family: var(--family-Inter);
  background: #ffffff;
  overflow-x: hidden;
}

a,
a:hover {
  text-decoration: none;
  color: initial;
}

textarea,
button,
input {
  outline: none;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.model-cont::-webkit-scrollbar {
  display: none;
}

.main-slider::-webkit-scrollbar {
  height: 8px;
}

.main-slider::-webkit-scrollbar-track {
  background: #ebebeb;
}

.main-slider::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 40px;
}

@media screen and (min-width: 1280px) {
  .main-slider::-webkit-scrollbar {
    display: none;
  }
}
